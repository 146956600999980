import dayjs from "dayjs";

export const convertUTCBefore = (datetime: string) => {
  if (datetime !== "" && datetime !== null) {

    // dayjs.extend(utc)
    const dateTime = dayjs(datetime).startOf('date').toISOString();
    return dateTime
  }
  return datetime

}
export const convertUTCAfter = (datetime: string) => {
  if (datetime !== "" && datetime !== null) {

    // dayjs.extend(utc)
    const dateTime = dayjs(datetime).endOf('date').toISOString();


    return dateTime
  }
  return datetime

}

export const convertDateTime = (datetime: string) => {
  if (datetime) {
    // dayjs.extend(utc)
    const dateTime = dayjs(datetime);
    const time = dateTime.format('DD/MM/YYYY hh:mm:ss A');
    return time
  }
  return datetime
}