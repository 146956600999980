import Card from "components/card";
import CountUp from "react-countup";

const Widget = (props: {
  icon: JSX.Element;
  title: string;
  subtitle: string;
  count?: Boolean;
  money?: Boolean;
}) => {
  const { icon, title, subtitle, count, money } = props;
  return (
    <Card extra="!flex-row flex-grow items-center rounded-[20px]">
      <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
        <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
          <span className="flex items-center text-brand-500 dark:text-white">
            {icon}
          </span>
        </div>
      </div>

      <div className="h-50 ml-4 flex w-auto flex-col justify-center">
        <p className="font-dm text-sm font-medium text-gray-600">{title}</p>

        {
          count ? <>
            {
              money ? <CountUp className="text-xl font-bold text-navy-700 dark:text-white" duration={1} start={0} end={Number(subtitle)} decimals={2}
                decimal="," /> : <CountUp className="text-xl font-bold text-navy-700 dark:text-white" duration={1} start={0} end={Number(subtitle)} />
            }
          </> : <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            {subtitle}
          </h4>
        }
      </div>
    </Card>
  );
};

export default Widget;
