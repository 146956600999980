import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import { useState } from "react";
import { Button, Spinner } from "flowbite-react";
import toast from "react-hot-toast";
import { partnerDashboardAPI } from "server";
import { useNavigate } from "react-router-dom";

export default function SignIn() {

  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [disabled, setDisabled] = useState<boolean>(false)
  const navigate = useNavigate()

  const login = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (email && password) {
      try {
        const res: any = await partnerDashboardAPI.Login(email, password)
        if (res?.code == 0) {
          sessionStorage.setItem("login", JSON.stringify(true))
          sessionStorage.setItem("admin", JSON.stringify(res.data.partnerInfo))
          sessionStorage.setItem("accessToken", JSON.stringify(res.data.token.accessToken))
          navigate("/admin")
        } else {
          toast.error("Please check your email or password then try again!")
        }

      } catch (error) {
        toast.error('An unexpected error occurred');
      }

    } else {
      toast.error("Please enter your email and password")
    }
  }


  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your <span className="font-bold">Username</span> and <span className="font-bold">Password</span> to sign in!
        </p>
        {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Sign In with Google
          </h5>
        </div>
        <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div> */}
        {/* Email */}
        <form onSubmit={login}>
          <InputField
            variant="auth"
            extra="mb-3"
            label="Username*"
            placeholder="Enter your Username"
            id="username"
            type="text"
            setValue={setEmail}
          />

          {/* Password */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="Enter your password"
            id="password"
            type="password"
            setValue={setPassword}
          />
          {/* Checkbox */}
          {/* <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a>
        </div> */}
          {/* <button className={`${disabled === true
          ? "pointer-events-none bg-gray-600 dark:bg-gray-600" : "text-white bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"} linear mt-2 w-full rounded-xl  py-[12px] text-base font-medium transition duration-200 cursor-pointer `}>
          Sign In
        </button> */}
          <Button type="submit" isProcessing={disabled} disabled={disabled} label="Sign In" className="w-full flex justify-center text-white bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 linear mt-2 rounded-xl py-[12px] text-base font-medium transition duration-200 cursor-pointer">
            Sign In
          </Button>
        </form>
        {/* <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </a>
        </div> */}
      </div>
    </div>
  );
}
