import Card from "components/card";
import ReactApexChart from "react-apexcharts";

const TrafficsCATGMChart = ({ data }: any) => {

  const series: string[] = data?.map((item: any) => item?.date)
  const totalUsers: number[] = []
  const gameUsers: number[] = []
  const newUsers: number[] = []

  if (series?.length > 0) {
    series?.forEach((item: any, index: number) => {
      totalUsers.push((data[index]?.totalUsers) || 0)
      gameUsers.push(data[index]?.gameUsers || 0)
      newUsers.push(data[index]?.newUsers || 0)

    })
  }

  const dataChart = [
    {
      name: "TOTAL USERS",
      data: totalUsers,
    },
    {
      name: "NEW USERS",
      data: newUsers,
    },
    {
      name: "GAME USERS",
      data: gameUsers,
    },
  ]


  const options: any = {
    chart: {

      toolbar: {
        show: false,
      },
    },
    // colors:['#ff3322','#faf']
    tooltip: {
      style: {
        fontSize: "12px",
        backgroundColor: "#000000",
      },
      theme: "dark",
      onDatasetHover: {
        style: {
          fontSize: "12px",
        },
      },
    },
    xaxis: {
      categories: series,
      show: false,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      min: 0,
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
    },

    grid: {
      borderColor: "rgba(163, 174, 208, 0.3)",
      show: true,
      yaxis: {
        lines: {
          show: true,
          opacity: 0.5,
        },
      },
      row: {
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    fill: {
      type: "solid",
      colors: ["#5E37FF", "#6AD2FF", "#E1E9F8"],
    },
    legend: {
      show: true,
    },
    colors: ["#5E37FF", "#6AD2FF", "#E1E9F8"],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        columnWidth: "20px",
      },
    },
  }


  return (
    <Card extra="flex flex-col bg-white w-full h-full justify-start rounded-3xl py-6 px-2 text-center">

      <div className="md:mt-16 lg:mt-0">
        <div className="h-[250px] w-full xl:h-[350px]">
          <ReactApexChart series={dataChart} options={options} type="bar" width="100%"
            height="100%" />
        </div>
      </div>
    </Card>
  );
};

export default TrafficsCATGMChart;
