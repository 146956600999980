export const partnerTrafficsColumns = [
    {
        Header: 'Date',
        key: "date"
    },
    {
        Header: 'Quest Users',
        key: "questUsers"
    },
    {
        Header: 'Verified Users',
        key: "verifiedUsers"
    }
]