import Card from "components/card";
import ReactApexChart from "react-apexcharts";

const TrafficsPartnerChart = ({ data }: any) => {

  const series: string[] = data?.map((item: any) => item?.date)
  const questUsers: number[] = []
  const verifiedUsers: number[] = []

  if (series?.length > 0) {
    series?.forEach((item: any, index: number) => {
      questUsers.push(data[index]?.questUsers || 0)
      verifiedUsers.push(data[index]?.verifiedUsers || 0)
    })
  }

  const dataChart = [
    {
      name: "QUEST USERS",
      data: questUsers,
    },
    {
      name: "VERIFIED USERS",
      data: verifiedUsers,
    },
  ]

  const options: any = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    // colors:['#ff3322','#faf']
    tooltip: {
      style: {
        fontSize: "12px",
        backgroundColor: "#000000",
      },
      theme: "dark",
      onDatasetHover: {
        style: {
          fontSize: "12px",
        },
      },
    },
    xaxis: {
      categories: series,
      show: false,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      color: "black",
      min: 0,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
    },

    grid: {
      borderColor: "rgba(163, 174, 208, 0.3)",
      show: true,
      yaxis: {
        lines: {
          show: true,
          opacity: 0.5,
        },
      },
      row: {
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    fill: {
      type: "solid",
      colors: ["#5E37FF", "#6AD2FF"],
    },
    legend: {
      show: true,
    },
    colors: ["#5E37FF", "#6AD2FF"],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        columnWidth: "20px",
      },
    },
  }


  return (
    <Card extra="flex flex-col bg-white w-full h-full justify-center rounded-3xl py-6 px-2 text-center">
      {/* <div className="mb-auto flex items-center justify-between px-6">
        <h2 className="text-lg font-bold text-navy-700 dark:text-white">
          Weekly Revenue
        </h2>
        <button className="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
          <MdBarChart className="h-6 w-6" />
        </button>
      </div> */}

      <div className="md:mt-16 lg:mt-0">
        <div className="h-[250px] w-full xl:h-[350px]">
          {/* {
            dataChart && options && <BarChart
              chartData={dataChart}
              chartOptions={options}
            />
          } */}
          <ReactApexChart series={dataChart} options={options} type="bar" width="100%"
            height="100%" />
        </div>
      </div>
    </Card>
  );
};

export default TrafficsPartnerChart;
