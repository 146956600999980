import { axiosClient } from './axiosClient'
import { AxiosResponse } from 'axios'

class PartnerDashboardAPI {
  getHeader = () => {
    let adminId = sessionStorage.getItem("admin")
    let token = sessionStorage.getItem("accessToken")

    if (adminId && token) {
      adminId = JSON.parse(adminId).partnerID
      token = JSON.parse(token)
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-client-id": adminId
      },
      withCredentials: true,
    }

    return config
  }

  Login = (email: string, password: string) => {
    const url = `/partner/login`
    const data = {
      "email": email,
      "password": password
    }
    const config = this.getHeader()
    return axiosClient.post(url, data, config)
  }

  getProfile = (partnerID: string) => {
    const url = `/partners/getPartnerInfo?partnerID=${partnerID}`
    const config = this.getHeader()
    return axiosClient.get(url, config)
  }

  getTrackingReport = (partnerID: string, fromDate: string, toDate: string) => {
    const url = `/partners/getTrackingReport?partnerID=${partnerID}&fromDate=${fromDate}&toDate=${toDate}`
    const config = this.getHeader()
    return axiosClient.get(url, config)
  }

  getUserPaymentTotal = (partnerID: string) => {
    const url = `/partners/getUserPaymentTotal?partnerID=${partnerID}`
    const config = this.getHeader()
    return axiosClient.get(url, config)
  }

  getReportLogTotal = () => {
    const url = `/partners/getReportLogTotal`
    const config = this.getHeader()
    return axiosClient.get(url, config)
  }



}
const partnerDashboardAPI = new PartnerDashboardAPI()
export default partnerDashboardAPI