import React from 'react'
import EmptyIcon from "../../assets/svg/EmptyIcon.svg"

const NoData = () => {
    return (
        <div className='w-full h-full grid place-items-center'>
            <div className="flex flex-col items-center justify-center gap-4">
                <img src={EmptyIcon} alt="" className='h-[100px]' />
                <span>No data</span>
            </div>
        </div>
    )
}

export default NoData