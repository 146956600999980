import { Card } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import { partnerDashboardAPI } from 'server'
import { partnerLogType } from 'variables/refType'

const TotalLifetime = () => {
    const [report, setReport] = useState<any>()

    const getReportLogTotal = async () => {

        try {
            const res: any = await partnerDashboardAPI.getReportLogTotal()
            if (res.code === 0) {
                setReport(res.data)
            }
        } catch (error) {
            console.log("🚀 ~ getReportLogTotal ~ error:", error)

        }

    }

    useEffect(() => {
        getReportLogTotal()
    }, [])
    return (

        <Card className='w-full h-full rounded-[20px]'>
            <h3 className='text-xl font-bold text-indigo-700 dark:text-white'>Total Lifetime Tracking:</h3>
            <div className='grid grid-cols-1 lg:grid-cols-3 justify-center items-center gap-x-4 gap-2'>{
                report && report?.length > 0 ? report.map((quest: any, index: number) => (
                    <Card className="flex-grow h-full rounded-[20px]">
                        <div className='flex items-start justify-start gap-x-2 text-md font-bold text-indigo-700 dark:text-white'>
                            <div className="">{index + 1}.</div>
                            <p>Quest: <span className='text-navy-700 dark:text-white'>{quest.questValue}</span></p>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 text-center mt-auto ">
                            {
                                [partnerLogType.DO_QUEST, partnerLogType.VERIFIED_QUEST].map((logType) => {
                                    const typeData = quest?.report?.find((type: any) => type._id === logType);
                                    if (!typeData) return null;

                                    return (
                                        <div key={logType} className=''>
                                            {logType === partnerLogType.DO_QUEST &&
                                                <div className='flex flex-col justify-start items-center font-dm text-sm font-medium text-gray-600'>
                                                    <span>Total Quest Users</span>
                                                    <span className='text-xl font-bold text-navy-700 dark:text-white'>
                                                        {typeData.totalTrackingCount}
                                                    </span>
                                                </div>
                                            }
                                            {logType === partnerLogType.VERIFIED_QUEST &&
                                                <div className='flex flex-col justify-start items-center font-dm text-sm font-medium text-gray-600'>
                                                    <span>Total Verified Users</span>
                                                    <span className='text-xl font-bold text-navy-700 dark:text-white'>
                                                        {typeData.totalTrackingCount}
                                                    </span>
                                                </div>
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </Card>
                )) : <p className='text-xl font-bold text-indigo-700 dark:text-white text-center'>Nodata</p>
            }
            </div>
        </Card >
    )
}

export default TotalLifetime