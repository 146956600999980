export const catgmTrafficsColumns = [
  {
    Header: 'Date',
    key: "date"
  },
  {
    Header: 'Total Users',
    key: "totalUsers"
  },
  {
    Header: 'New Users',
    key: "newUsers"
  },
  {
    Header: 'Game Users',
    key: "gameUsers"
  },
]