import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface SplineChartProps { }

const SplineChart = ({ data }: any) => {
    const categories = data?.map((item: any) => item?.date)
    const questUsers = data?.map((item: any) => item?.questUsers)


    const verifiedUsers = data?.map((item: any) => item?.verifiedUsers)
    // const categories = data?.map((item: any) => item.date)

    const options: ApexOptions = {
        xaxis: {
            categories: categories,
            labels: {
                show: true,
                style: {
                    colors: "#A3AED0",
                    fontSize: "14px",
                    fontWeight: "500",
                },
            },
            axisBorder: {
                show: true,
            },
            // axisTicks: {
            //     show: true,
            // },
        },
        yaxis: {
            axisBorder: {
                show: true,
            },
            labels: {
                show: true,
                style: {
                    colors: "#A3AED0",
                    fontSize: "14px",
                    fontWeight: "500",
                },
            },
        },
        title: {
            text: 'Quest and Verified Users',
            align: 'center',
        },
        stroke: {
            curve: 'smooth',
        },
        fill: {
            type: "solid",
            colors: ["#5E37FF", "#6AD2FF", "#E1E9F8"],
        },
        legend: {
            show: true,
        },
        colors: ["#5E37FF", "#6AD2FF", "#E1E9F8"],
        grid: {
            borderColor: "rgba(163, 174, 208, 0.3)",
            show: true,
            row: {
                opacity: 0.5,
            },
        },
    };

    const series: ApexAxisChartSeries = [
        {
            name: 'Quest Users',
            data: questUsers,
        },
        {
            name: 'Verified Users',
            data: verifiedUsers,
        },
    ];

    return (
        <ReactApexChart
            options={options}
            series={series}
            type="area"
            height={350}
        />
    );
};

export default SplineChart;