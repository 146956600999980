/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import axios, { InternalAxiosRequestConfig } from 'axios'
import queryString from 'query-string'

const axiosClient = axios.create({
  baseURL: "https://partner-api.catgoldminer.ai/",
  headers: {
    'content-type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    // 'Device-Type': 'Webapp'
  },
  paramsSerializer: (params) => queryString.stringify(params)
})

axiosClient.defaults.timeout = 90000

axiosClient.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    if (error.response?.status === 401) {
      sessionStorage.removeItem("login")
      sessionStorage.removeItem("admin")
      sessionStorage.removeItem("accessToken")
      window.location.href = '/auth/sign-in'
    }
    console.error('Request Error', error)
  }
)

export { axiosClient }
